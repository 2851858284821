import React, { useState } from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={13}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 13</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={true}
            setFullScreen={setStudentFullScreen}
          >
            <p>Before we make a decision about our health, we need to think about what the benefits may be and what the risks may be.</p>
            <p>Read the following article to think about risks and benefits. (Your teacher will tell you which option to choose.) In your science notebook, write some of the benefits and risks that the woman in the story faced. Be prepared to share your ideas in a class discussion.</p>

            <div className="mt-5 flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-3">
              <Button
                action="secondary"
                color="indigo"
                href="/high-school/lesson-4/step-13a"
                title="Option 1"
              >
                Option 1
              </Button>

              <Button
                action="secondary"
                color="indigo"
                href="/high-school/lesson-4/step-13b"
                title="Option 2"
              >
                Option 2
              </Button>
            </div>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={true}
            setFullScreen={setTeacherFullScreen}
          >
            <p>To continue learning about risks and benefits, students will read about a case from a <em className="italic">Scientific American</em> article. You can choose which option is appropriate for your students. Option 1 takes students to the original article. Option 2 is a simplified reading. The main thing that students should look for are the types of risks that the woman in the article faced.</p>
            <p>When students are finished, hold a class discussion to talk about risks and benefits.</p>

            <p>The main benefit the woman in the story wanted was an enhanced appearance. She encountered more risks than she wanted or expected, including the following:</p>

            <UnorderedList>
              <li>Procedure would not work as well as she wanted.</li>
              <li>Procedure had unwanted side effects (which is the result she got from the first procedure).</li>
              <li>She spent much more money than she expected when she had to have a second procedure to fix the problem caused by the first procedure. (She may not have money to do something else she wanted because she spent the money on a second procedure.)</li>
              <li>She spent a lot of time getting the problem fixed.</li>
              <li>There is a chance that the problem could recur in the future.</li>
            </UnorderedList>

            <p>You may also want to include some discussion about how the woman could have avoided being in this situation. Some ideas would be</p>

            <UnorderedList>
              <li>asking more questions before the procedure about its safety and risks,</li>
              <li>finding out how many times the doctor has done this procedure before, and</li>
              <li>asking about whether the medications or substances used in the procedure are approved for this use.</li>
            </UnorderedList>

            <p>You can learn more about "off-label" uses of FDA-approved drugs by reading</p>
            <a
              className="text-indigo-600 hover:underline overflow-hidden block"
              href="https://www.fda.gov/forpatients/other/offlabel/default.htm"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://www.fda.gov/forpatients/other/offlabel/default.htm&nbsp;&nbsp;<sup><i className="fas fa-external-link-alt"/></sup>
            </a>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

